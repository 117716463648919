<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { TabsList, type TabsListProps } from 'radix-vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	TabsListProps & { class?: HTMLAttributes['class'] }
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<TabsList
		v-bind="delegatedProps"
		:class="cn('w-full inline-flex', props.class)"
	>
		<slot />
	</TabsList>
</template>
