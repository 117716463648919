<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { TabsTrigger, type TabsTriggerProps, useForwardProps } from 'radix-vue';
import { cn } from '@laam/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const tabVariants = cva(
	/*tw*/ 'w-full flex px-xs pb-lg text-sm font-semibold justify-center hover:text-gray text-gray-500 border-b-2  border-b-gray-200 data-[state=active]:text-gray-800 data-[state=active]:border-b-gray-500',
	{
		variants: {
			variant: {
				default: '', // Default variant
				primary:
					'data-[state=active]:text-blue-600 border-b-4 data-[state=active]:border-blue-600',
				secondary: 'bg-gray-200 text-gray-700',
				white:
					/*tw*/ 'data-[state=active]:bg-white data-[state=active]:text-gray-700 data-[state=active]:!border-0 !py-md rounded-medium data-[state=active]:!shadow-sm border-0',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
);

export type TadgeVariants = VariantProps<typeof tabVariants>;

const props = defineProps<
	TabsTriggerProps & {
		variant?: TadgeVariants['variant'];
		class?: HTMLAttributes['class'];
	}
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
	<TabsTrigger
		v-bind="forwardedProps"
		:class="cn(tabVariants({ variant: props.variant }), props.class)"
	>
		<slot />
	</TabsTrigger>
</template>
